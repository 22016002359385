import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In `}<a parentName="p" {...{
        "href": "https://try.skyflow.com"
      }}>{`try.skyflow.com`}</a>{` only, you can generate an API bearer token by clicking your name icon in the top right and selecting `}<strong parentName="p">{`Generate API Bearer Token > Generate Token`}</strong>{`. `}</p>
    <p>{`Click the Copy icon to copy the token. It is valid for 24 hours. `}</p>
    <p><img parentName="p" {...{
        "src": "../../../../images/generate_bearer_token.gif",
        "alt": "generate_a_bearer_token"
      }}></img>{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      